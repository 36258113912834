import { pick } from 'lodash-es'
import { SECTION_TYPE_NAME } from '#section/constant'
import type { SectionItem, SectionSyncPayload, SectionUpdatePayload } from '#section/types'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'

export const useSectionDataConvert = () => {
  const getSectionIdentifier = (id: string) => getIdentifier(id, SECTION_TYPE_NAME)
  const fields = ['name', 'boardId', 'closed', 'position', 'statusId']

  // For app.add
  const getOptimisticSection = (id: string | null, payload: SectionUpdatePayload, forFields = false): Partial<SectionItem> & { __typename?: string } => {
    const defaults = { closed: false }
    const section = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? section : { ...section, __typename: SECTION_TYPE_NAME }
  }

  // For app.update
  const getOptimisticSectionFields = (payload: SectionUpdatePayload) => {
    const section = getOptimisticSection(null, payload, true)
    const fields = convertObjectToFields(section)
    return fields
  }

  // For socket.add use raw data from socket messages, no conversion needed

  // For socket.update
  const getSyncSectionFields = (payload: SectionSyncPayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<SectionItem>
  }

  return {
    getSectionIdentifier,
    getOptimisticSection,
    getOptimisticSectionFields,
    getSyncSectionFields,
  }
}
